/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cover from "../../assets/cover-mob.svg";
import insta from "../../assets/blog-instagram.svg";
import tik from "../../assets/blog-tiktok..svg";
import twit from "../../assets/blog-twitter..svg";
import menuBg from "../../assets/menu-bg.svg";
import search from "../../assets/search.svg";
import burg from "../../assets/burg.svg";
import exit from "../../assets/exit.svg";
import { Card } from "./Card";
import { Blog } from "../../pages/Blog";
import { Modal } from "./Modal";
import { useGetBlogsMutation, useGetTagsMutation } from "../../store/Apis/UserApi";
import { colors } from "../../utils/constants";
import "./blog.css";
import { TermsModal } from "../Authentication/TermsModal";

export function BlogMobile() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null! as Blog);
  const [selectedTag, setSelectedTag] = useState("");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [blogs, setBlogs] = useState([] as Blog[]);
  const [tags, setTags] = useState([] as string[]);
  const [totalPages, setTotalPages] = useState(1);
  const [getBlogs] = useGetBlogsMutation();
  const [getTags] = useGetTagsMutation();
	const [showModal, setShowModal] = useState(false)
	const [showMenu, setShowMenu] = useState(false)

  function loadBlogs(search:string, tags:string[],page:number,reset:boolean) {
    getBlogs({page:page,limit:4,search:search, tags}).unwrap().then((res) => {
      if (res) {
        if(reset)
          setBlogs(res.data)
        else
        setBlogs((prev) => {
          return [...prev, ...res.data]
        });
        setTotalPages(res.totalPages)
    }
  }
  ).catch((err) => {
    console.log("blogs",err);
  });
  }

  useEffect(() => {
    getTags(void 0).unwrap().then((res)=>{
      if(res?.success)
        setTags(res.data)
    }).catch((err)=>{
      console.log("tags",err)
    })
   loadBlogs("",[],1,true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
  return (
    <Suspense>
      <div className={`relative w-full h-full min-h-screen bg-gray-blog flex flex-col phone:visible web:hidden`}
      style={{overflow:showMenu?"hidden":"auto"}}
      >
				{showModal && <TermsModal params={""} setShowModal={() => setShowModal(false)}></TermsModal>}
        {selected && (
          <Modal
            blog={selected}
            onSelect={setSelected}
            back={t("blog.title") || "العودة"}
          ></Modal>
        )}
          <div
          className={`absolute w-full h-screen top-0 z-30 grow flex flex-col items-start gap-8 px-12 lg:hidden ${!showMenu ? "hidden" : ""
            }`}
            style={{ backgroundImage: `url(${menuBg})`,backgroundRepeat:"no-repeat",backgroundSize:"cover",overflow:"hidden" }}
        >
          <img src={exit} alt="menu" className="w-6 mt-12" onClick={()=> setShowMenu(false)}></img>
          <a href="/join"  className="text-white text-2xl font-normal hover:underline cursor-pointer">
              {t("herobanner.join")}
            </a>
            <a href="/#how-to" className="text-white text-2xl font-normal hover:underline cursor-pointer">
              {t("navbar.how")}
            </a>
            <a href="/#about" className="text-white text-2xl font-normal hover:underline cursor-pointer">
              {t("navbar.about")}
            </a>
        </div>
        <div className="absolute top-0 w-full flex flex-row">
          <div className="w-[75%] pt-4 px-8 flex flex-row items-center justify-between ">
            <img src={burg} alt="menu" className="w-6" onClick={()=> setShowMenu(true)}></img>
            <h3 className="text-white font-Inter font-bold text-xl">
              THE FUNNY1S
            </h3>
          </div>
        </div>
        <img
          src={cover}
          className="w-full"
          alt="family having fun playing"
        ></img>
        <div className="bg-gray-blog pb-12 h-full grow">
          <div className="mt-[-200px] relative w-full h-full flex flex-col bg-transparent z-20 items-center justify-start">
            <h1
              className="text-3xl text-center text-white font-bold font-Inter"
              style={{ textShadow: "8px 2px 2px rgba(0,0,0,0.6)" }}
            >
              {t("blog.title")}
            </h1>
            <div className=" w-full flex flex-row gap-8 mt-4 px-8">
              <button onClick={()=>{
                  setCurrentPage(1);
                  loadBlogs(searchText,[],1,true)
                }} className="w-[25%] h-10 bg-blue-blog p-2 rounded-md border-2 font-Inter font-bold border-white shadow-lg">
                {t("blog.search")}
              </button>
              <div className="relative w-[75%]">
                {" "}
                <input
                  type="text"
                  onChange={(event)=>{setSearchText(event.target.value)}}
                  className="w-full px-8 h-10 bg-white border-2 border-black rounded-md font-Inter shadow-lg placeholder:text-rose-500"
                ></input>
                <img
                  src={search}
                  alt="search"
                  className="absolute right-2 top-3 w-4"
                ></img>
              </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-8 mt-8 px-8">
              {blogs.length>0 && blogs.map((b,index) => (
                <Card key={index} onSelect={setSelected} blog={b} />
              ))}
            </div>
            {currentPage<totalPages && <button onClick={()=>{loadBlogs(searchText,[selectedTag],currentPage+1,false)
              setCurrentPage(currentPage+1)
            }} className="w-[90%] bg-transparent text-white rounded-md text-md border-2 font-Inter border-white mt-10 p-1">
              {t("blog.load_more")}
            </button>}
            <div className="flex flex-row w-full items-center justify-center px-2 pt-12">
              <div
                className="grow mx-2"
                style={{
                  height: "2px",
                  borderBottom: "1px solid rgba(255,255,255,.55)",
                }}
              ></div>
              <h3 className="text-white font-Inter text-xl">
                {t("blog.hashtags")}
              </h3>
              <div
                className="grow mx-2"
                style={{
                  height: "2px",
                  borderBottom: "1px solid rgba(255,255,255,.55)",
                }}
              ></div>
            </div>
            <div className="flex flex-row flex-wrap items-center justify-center px-[10%] py-4 gap-4">
            {
              tags.map((tag,index)=> <span key={index} onClick={()=>{
                setSelectedTag((prev)=>{
                  if(prev && prev===tag)
                  {
                    loadBlogs(searchText,[],1,true)
                    return ""
                  }
                  loadBlogs(searchText,[tag],1,true)
                  return tag
                })
                setCurrentPage(1)
              }} style={{backgroundColor:colors[index%9]}} className={`${selectedTag === tag ? 'dimmed' : 'normal'} w-fit font-bold text-xl px-12 py-2 rounded-md text-center cursor-pointer`}>{tag}</span>)
            }
            </div>
          </div>
        </div>
        <div className="w-full bg-black flex flex-col items-center gap-2 py-4 ">
          <h3 className="text-white font-Inter font-bold text-xl p-4">
            THE FUNNY1S
          </h3>
          <div className="flex flex-row gap-24 p-4">
            <img className="w-4 cursor-pointer" src={twit} alt="twitter" onClick={() => { window.open('https://twitter.com/thefunny_ones', '_blank'); }}></img>
            <img className="w-4 cursor-pointer" src={tik} alt="tiktok" onClick={() => { window.open('https://www.tiktok.com/@funnyzedbhx', '_blank'); }}></img>
            <img className="w-4 cursor-pointer" src={insta} alt="instagram"  onClick={() => { window.open('https://www.instagram.com/the.funny1s/', '_blank'); }}></img>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              borderBottom: "1px solid rgba(255,255,255)",
            }}
          ></div>
          <h2 className="text-white text-md hover:underline cursor-pointer" onClick={() => setShowModal(true)}>{t('footer.terms')}</h2>
          <h2 className="text-white text-sm font-light" dir="ltr" >© Copyright {new Date().getFullYear()}. All rights reserved</h2>
        </div>
      </div>
    </Suspense>
  );
}
