import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { BlogDesktop } from "../components/Blog/BlogDesktop";
import { BlogMobile } from "../components/Blog/BlogMobile";

export type Blog = { title: string; imgSrc: string; content: string };

export function BlogPage() {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  
  return (
    <Suspense>
      <Helmet>
        <title>{t("helmet.blog_title")}</title>
        <meta
          name="description"
          content={
            t("helmet.blog_desc") || "Propaganda is an online funny board game"
          }
        ></meta>
      </Helmet>
      <div className="w-full h-full overflow-hidden">
        <BlogMobile />
        <BlogDesktop/>
      </div>
    </Suspense>
  );
}
